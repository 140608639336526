import React from 'react';
import { TransportadoraProvider } from './TransportadoraContext';
import TransportadoraContent from './TransportadoraContent';

const Transportadora: React.FC = () => {
  return (
    <TransportadoraProvider>
      <TransportadoraContent />
    </TransportadoraProvider>
  );
};
export default Transportadora;
