import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { GridRowParams } from '@material-ui/data-grid';
import { nanoid } from 'nanoid';

import { useForm } from 'react-hook-form';

import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  AddCondicao,
  Condicao,
  CidadeParams,
  CondicaoSelect,
  Options,
  TransportadoraContextData,
  TransportadoraProviderProps,
} from './types';
import { validarCNPJ } from '../../utils/functions';
import { loadingContext } from '../../context/loading';
import { SelectType } from '~/components/NovosInputs/InputCidade/protocols';

import { condicao, schema } from './Validations';

export const TransportadoraContext = createContext(
  {} as TransportadoraContextData,
);

export const TransportadoraProvider: React.FC<TransportadoraProviderProps> = ({
  children,
}) => {
  const { setLoading } = useContext(loadingContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [tab, setTab] = useState<any>('geral');

  const [loadingIcon, setLoadingIcon] = useState<string>();
  const [cidade, setCidade] = useState<CidadeParams>();
  const [clearCidade, setClearCidade] = useState<boolean>(false);
  const [finalizadoras, setFinalizadoras] = useState<Options[]>([]);
  const [inputSite, setInputSite] = useState<any>('');
  const [cidadeSelected, setCidadeSelected] = useState<SelectType>();

  // Condicao
  const [initCondicao, setInitCondicao] = useState(false);
  const [changeCondicao, setChangeCondicao] = useState<boolean>(false);
  const [condicoes, setCondicoes] = useState<AddCondicao[]>([]);
  const [diasCondicao, setDiasCondicao] = useState(30);
  const [clickSubmit, setClickSubmit] = useState<boolean>(false);
  const [condicoesOptions, setCondicoesOptions] = useState<Options[]>([]);
  const [condicaoAEditar, setCondicaoAEditar] = useState<any>(undefined);

  const [condicaoInsert, setCondicaoInsert] = useState<any>([]);
  const [condicaoUpdate, setCondicaoUpdate] = useState<any>([]);
  const [condicaoDelete, setCondicaoDelete] = useState<any>([]);
  const [condicaoSelected, setCondicaoSelected] = useState<CondicaoSelect>({
    value: undefined,
    label: '',
  });
  /**
   * useForm para controle do formulário Pessoa
   */
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  /**
   * useForm para controle do formulário Condicao
   */
  const {
    register: condicaoRegister,
    handleSubmit: handleSubmitCondicao,
    control: condicaoControl,
    reset: resetCondicao,
    setValue: setValueCondicao,
    getValues: getValuesCondicao,
    formState: formStateCondicao,
  } = useForm({
    resolver: yupResolver(condicao),
    reValidateMode: 'onBlur',
  });

  /**
   * Métodos das condições
   */

  useEffect(() => {
    (async () => {
      if (clickSubmit) {
        if (errors.email) {
          const values = getValues();
          if (values.email) {
            setClickSubmit(false);
            toast.warning('E-mail inválido');
          }
        }
      }
    })();
  }, [errors.estados, errors.email, clickSubmit, getValues]);

  const documentoValido = (doc: string): boolean => {
    const validarDocumento = (
      numDocumento: string,
      mensagemErro: string,
    ): boolean => {
      if (!numDocumento) {
        toast.warn(mensagemErro);
        return false;
      }

      const isValid = validarCNPJ(numDocumento);

      if (!isValid) {
        toast.warn('CNPJ informado é inválido');
      }

      return isValid;
    };

    return validarDocumento(doc, 'CNPJ informado é inválido');
  };

  const resetFormData = useCallback(() => {
    const resetData = {
      num_cnpj: '',
      num_ie: '',
      rntrc: '',
      bairro: '',
      celular: '',
      cep: '',
      cidades: '',
      complemento: '',
      contato: '',
      email: '',
      estados: '',
      logradouro: '',
      name: '',
      numero: '',
      site: '',
      telefone: '',
    };
    setCidade(undefined);
    setClearCidade(true);
    reset(resetData);
    setTab('geral');
    setDiasCondicao(30);
    resetCondicao();
    setCondicoes([]);
    setCondicaoAEditar('');
    setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
    setValueCondicao('numCondicao', 30);
    setClearCidade(false);
    setCondicaoInsert([]);
    setCondicaoUpdate([]);
    setCondicaoDelete([]);
  }, [reset, resetCondicao, setValueCondicao]);

  /**
   * Métodos genéricos da tela
   */
  const onRowClick = useCallback(async (param: GridRowParams) => {
    const { row } = param;
    const {
      cod_transportadora,
      cod_pessoa,
      num_rntrc,
      flg_inativo,
      cod_contato,
      cod_endereco,
      des_bairro,
      des_cidade,
      des_complemento,
      des_contato,
      des_logradouro,
      des_uf,
      email,
      nome_pessoa,
      num_celular,
      num_cep,
      num_cpf_cnpj,
      num_endereco,
      num_ie,
      des_transportadora,
      num_telefone,
      site,
    } = row;
    setUpdate(true);
    setShowSearch(false);

    if (num_cpf_cnpj) {
      setValue('num_cnpj', num_cpf_cnpj);
    }
    setValue('cod_pessoa', cod_pessoa);
    setValue('cod_transportadora', cod_transportadora);
    setValue('cod_contato', cod_contato);
    setValue('cod_endereco', cod_endereco);
    setValue('num_ie', num_ie);
    setValue('rntrc', num_rntrc);
    setValue('inativo', flg_inativo);
    setValue('name', (nome_pessoa || undefined) ?? des_transportadora);
    setValue('telefone', num_telefone);
    setValue('celular', num_celular);
    setValue('contato', des_contato);
    setValue('email', email);
    setValue('site', site);
    setValue('cep', num_cep);
    setValue('logradouro', des_logradouro);
    setValue('numero', num_endereco);
    setValue('bairro', des_bairro);
    setValue('complemento', des_complemento);
    setCidade({
      cidade: des_cidade,
      uf: des_uf,
    });
    setDiasCondicao(30);
    setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
    setValueCondicao('numCondicao', 30);
    // Busca condicoes
    const { data } = await api.get(`/cliente-condicao`, {
      params: {
        cod_pessoa,
        tipo_condicao: 0,
      },
    });
    if (data.success) {
      if (data.message !== 'Nenhum registro encontrado.') {
        const options = data.data.map((cond: Condicao) => {
          const uuid = nanoid();
          return {
            uuid,
            id: uuid,
            dias: `${cond.num_condicao}`,
            condicao: cond.des_condicao,
            finalizadora: cond.des_finalizadora,
            codFinalizadora: cond.cod_finalizadora,
            codCondicao: cond.cod_condicao,
            cod_seq_pessoa_condicao: cond.cod_seq_pessoa_condicao,
          };
        });

        setCondicaoUpdate(options);

        setCondicoes(options);
      }
    }
  }, []);

  return (
    <TransportadoraContext.Provider
      value={{
        condicaoRegister,
        initCondicao,
        formStateCondicao,

        condicaoControl,
        finalizadoras,

        setInitCondicao,
        setValueCondicao,
        setChangeCondicao,
        diasCondicao,
        setDiasCondicao,
        condicoesOptions,
        condicaoAEditar,

        condicoes,

        inputSite,
        setInputSite,

        register,

        setLoadingIcon,
        loadingIcon,
        getValues,
        setValue,
        setCidade,
        cidadeSelected,
        setCidadeSelected,
        clearCidade,
        cidade,
        setFinalizadoras,
        setCondicoesOptions,
        setUpdate,
        setShowSearch,
        setClearCidade,
        setCondicoes,

        reset,
        setTab,
        resetCondicao,
        setCondicaoAEditar,

        setError,
        isUpdate,
        setLoader,
        setLoading,
        handleSubmit,
        control,

        loader,
        showSearch,
        setClickSubmit,
        tab,
        errors,
        documentoValido,
        onRowClick,
        resetFormData,
        handleSubmitCondicao,

        condicaoInsert,
        setCondicaoInsert,
        condicaoUpdate,
        setCondicaoUpdate,

        condicaoDelete,
        setCondicaoDelete,
        condicaoSelected,
        setCondicaoSelected,
      }}
    >
      {children}
    </TransportadoraContext.Provider>
  );
};
export const useTransportadora = () => {
  return useContext(TransportadoraContext);
};
