import { DataGrid, GridColumns } from '@material-ui/data-grid';

import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { GoPencil } from 'react-icons/go';
import { MdDeleteForever } from 'react-icons/md';
import { CancelButton, ConfirmButton } from '~/components/Buttons';
import InputNumberDias from '~/components/Inputs/inputNumberDias';
import { InputSelect } from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { ButtonRow, Container } from '../../styles';

import { useTransportadora } from '../../TransportadoraContext';
import { AddCondicao, Options } from '../../types';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';

const AbaCondicao: React.FC = () => {
  const {
    condicaoRegister,
    initCondicao,
    formStateCondicao,

    condicaoControl,
    finalizadoras,

    setInitCondicao,
    setValueCondicao,
    setChangeCondicao,

    diasCondicao,

    setDiasCondicao,
    condicoesOptions,

    condicaoAEditar,

    condicoes,
    setCondicaoAEditar,
    setCondicoes,
    handleSubmitCondicao,

    condicaoInsert,
    setCondicaoInsert,

    condicaoUpdate,
    setCondicaoUpdate,
    setCondicaoDelete,
    condicaoDelete,
    condicaoSelected,
    setCondicaoSelected,
  } = useTransportadora();

  const SaveCondicao = () => {
    handleCondicao();
    handleClearCondicao();
    setCondicaoAEditar('');
  };

  const handleClearCondicao = useCallback(() => {
    setCondicaoAEditar('');
    setDiasCondicao(30);
    setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
    setValueCondicao('condicaoFinalizadora', '');
    setValueCondicao('numCondicao', 30);
    setValueCondicao('cod_seq_pessoa_condicao', '');
    setCondicaoSelected({ value: undefined, label: '' });
  }, [
    setValueCondicao,
    setCondicaoAEditar,
    setDiasCondicao,
    setCondicaoSelected,
  ]);

  const handleCondicao = handleSubmitCondicao(async (data) => {
    const exists = condicoes.filter(
      (cond) =>
        cond.codFinalizadora === data.condicaoFinalizadora.value &&
        cond.codCondicao === data.condicao.value &&
        Number(cond.dias) === data.numCondicao &&
        condicaoAEditar?.uuid !== cond.uuid,
    );
    if (exists.length > 0) {
      toast.warning(`Já existe uma condição com essas informações.`);
      return;
    }
    if (condicaoAEditar?.uuid !== '' && condicaoAEditar?.uuid !== undefined) {
      const condicaoData: AddCondicao = {
        uuid: condicaoAEditar.uuid,
        id: condicaoAEditar.uuid,
        dias: data.numCondicao,
        condicao: data.condicao.label,
        finalizadora: data.condicaoFinalizadora.label,
        codFinalizadora: data.condicaoFinalizadora.value,
        codCondicao: data.condicao.value,
        cod_seq_pessoa_condicao: data.cod_seq_pessoa_condicao,
      };

      const hasInsertIndex: any = condicaoInsert.findIndex(
        (item: any) => item.uuid === condicaoAEditar?.uuid,
      );

      if (hasInsertIndex !== -1) {
        condicaoInsert[hasInsertIndex] = condicaoData;

        setCondicaoInsert(condicaoInsert);
      }
      const hasUpdateIndex: any = condicaoUpdate.findIndex(
        (item: any) => item.uuid === condicaoAEditar?.uuid,
      );

      if (hasUpdateIndex !== -1) {
        const newCondicaoUpdate = [...condicaoUpdate];
        newCondicaoUpdate[hasUpdateIndex] = condicaoData;

        setCondicaoUpdate(newCondicaoUpdate);
      }

      const newCondicoes = condicoes.map((oldCondicao) => {
        if (oldCondicao.uuid === condicaoAEditar?.uuid) return condicaoData;
        return oldCondicao;
      });

      setCondicoes(newCondicoes);

      handleClearCondicao();
      setCondicaoAEditar('');
    } else {
      const uuid = nanoid();
      const condicaoData: AddCondicao = {
        uuid,
        id: uuid,
        dias: data.numCondicao,
        condicao: data.condicao.label,
        finalizadora: data.condicaoFinalizadora.label,
        codFinalizadora: data.condicaoFinalizadora.value,
        codCondicao: data.condicao.value,
      };

      setCondicaoInsert([...condicaoInsert, condicaoData]);

      setCondicoes([...condicoes, condicaoData]);
      setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
      setValueCondicao('condicaoFinalizadora', { label: '', value: undefined });
      setValueCondicao('numCondicao', 30);
      setDiasCondicao(30);
      handleClearCondicao();
    }
  });

  const colunasCondicao: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'finalizadora',
      headerName: 'Finalizadora',
      type: 'string',
      width: 310,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'dias',
      headerName: 'Condição',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      sortable: true,
      headerClassName: 'super-app-theme--header align-left',
    },
    {
      field: 'condicao',
      headerName: 'Tipo da Condição',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: '',
      headerName: 'Ações',
      width: 180,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: (params: { row: any }) => {
        const onEdit = () => {
          const { row } = params;

          setValueCondicao('condicaoFinalizadora', {
            value: row.codFinalizadora,
            label: row.finalizadora,
          });

          setValueCondicao('condicao', {
            value: row.codCondicao,
            label: row.condicao,
          });

          setValueCondicao(
            'cod_seq_pessoa_condicao',
            row.cod_seq_pessoa_condicao,
          );

          setDiasCondicao(row.dias);
          setValueCondicao('numCondicao', row.dias);

          setCondicaoAEditar({ uuid: row.uuid });
          const elm = document.getElementById('collapseThree-transportadora');
          if (elm) {
            elm.removeAttribute('class');
            elm.classList.add('accordion-collapse', 'collapse', 'show');
          }
        };
        const onDelete = () => {
          const { row } = params;
          handleRemoveCondicao(row.uuid);
        };
        return (
          <>
            <ButtonRow
              type="button"
              onClick={() => {
                onEdit();
              }}
            >
              <GoPencil size={20} style={{ color: '#72ab90' }} /> Editar
            </ButtonRow>
            <ButtonRow type="button" onClick={onDelete}>
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Excluir
            </ButtonRow>
          </>
        );
      },
    },
  ];

  const handleRemoveCondicao = useCallback(
    async (uuid) => {
      const hasInsertIndex: any = condicaoInsert.findIndex(
        (item: any) => item.uuid === uuid,
      );
      const hasUpdateIndex: any = condicaoUpdate.findIndex(
        (item: any) => item.uuid === uuid,
      );
      const newCondicaoUpdate: any = [...condicaoUpdate];
      const newCondicaoInsert: any = [...condicaoInsert];

      if (hasInsertIndex !== -1) {
        newCondicaoInsert.splice(hasInsertIndex, 1);
        setCondicaoInsert(newCondicaoInsert);
      }
      if (hasUpdateIndex !== -1) {
        const newCondicaoDelete: any = [...condicaoDelete];
        newCondicaoDelete.push(condicaoUpdate[hasUpdateIndex]);
        setCondicaoDelete(newCondicaoDelete);

        newCondicaoUpdate.splice(hasUpdateIndex, 1);

        setCondicaoUpdate(newCondicaoUpdate);
      }

      setCondicoes([...newCondicaoUpdate, ...newCondicaoInsert]);

      handleClearCondicao();
    },
    [
      setCondicaoInsert,
      handleClearCondicao,
      setCondicoes,
      condicaoDelete,
      condicaoInsert,
      condicaoUpdate,
      setCondicaoDelete,
      setCondicaoUpdate,
    ],
  );

  return (
    <Container>
      <Row style={{ marginBottom: '60px' }}>
        <Col sm={12}>
          <Separator labelText="Condição de Pagamento" childrenWidth="170px">
            <button
              type="button"
              className="btnLimiteCreditoTransportadora"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree-transportadora"
              aria-expanded="false"
              aria-controls="collapseThree"
              style={{
                marginTop: '-7px',
                backgroundColor: '#F4F5F7',
              }}
            >
              <AiOutlinePlusCircle
                size={16}
                style={{ marginTop: '-2px', color: 'green' }}
              />{' '}
              Adicionar Condição
            </button>
          </Separator>
        </Col>
        {/* Inicio accordion */}
        <div
          className="accordion"
          id="accordionCondicao"
          style={{ marginBottom: '15px', padding: '0px' }}
        >
          <div
            id="collapseThree-transportadora"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionCondicao"
          >
            <div className="accordion-body">
              <Row>
                <Col
                  sm={12}
                  md={4}
                  className="finalizadoraContainer"
                  style={{ maxWidth: '30%' }}
                >
                  <div style={{ marginLeft: '-8px' }}>
                    <InputSelect
                      label="Finalizadora"
                      placeholder="Selecione"
                      name="condicaoFinalizadora"
                      register={condicaoRegister}
                      isError={
                        initCondicao &&
                        !!formStateCondicao.errors.condicaoFinalizadora
                      }
                      control={condicaoControl}
                      options={finalizadoras}
                      changeSelected={(selected) => {
                        setInitCondicao(false);
                        setValueCondicao('condicaoFinalizadora', selected);
                        setChangeCondicao(true);
                      }}
                    />
                  </div>
                </Col>

                <Col
                  className="inputDiasContainer"
                  sm={12}
                  md={1}
                  style={{ minWidth: '110px' }}
                >
                  <>
                    <InputNumberDias
                      label="Condição"
                      min={0}
                      value={diasCondicao}
                      placeholder=""
                      isDisabled={false}
                      onChange={(val: any) => {
                        if (val >= 0 || val === '') {
                          setValueCondicao('numCondicao', val);
                          setDiasCondicao(val);
                        }
                      }}
                    />
                  </>
                </Col>
                <Col
                  sm={12}
                  md={4}
                  className="tipoCondicao"
                  style={{ maxWidth: '100%' }}
                >
                  <div style={{ marginLeft: '-8px' }}>
                    <InputSelect
                      label="Tipo da Condição"
                      name="condicao"
                      register={condicaoRegister}
                      isError={!!formStateCondicao.errors.condicao}
                      control={condicaoControl}
                      options={condicoesOptions}
                      value={condicaoSelected.label}
                      changeSelected={(selected: Options) => {
                        setValueCondicao('condicao', selected);
                        setCondicaoSelected(selected);
                        setChangeCondicao(true);
                      }}
                    />
                  </div>
                </Col>
                <Col
                  sm={12}
                  md={3}
                  className="buttonContainer"
                  style={{ paddingRight: '0px' }}
                >
                  <div className="actButtonsCredito">
                    <ConfirmButton
                      style={{ height: '42px' }}
                      onClick={() => {
                        setInitCondicao(true);
                        SaveCondicao();
                      }}
                    >
                      {condicaoAEditar?.id !== '' &&
                      condicaoAEditar?.id !== undefined
                        ? 'Editar'
                        : 'Confirmar'}
                    </ConfirmButton>
                    <CancelButton
                      style={{ height: '42px' }}
                      onClick={handleClearCondicao}
                    >
                      Cancelar
                    </CancelButton>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {/* fim accordion */}
        <Col
          sm={12}
          className="dataTable"
          style={{ height: 280, width: '100%' }}
        >
          <Col
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              marginBottom: '50px',
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={condicoes}
                columns={colunasCondicao}
                pageSize={4}
                autoHeight
                sortModel={[
                  {
                    field: 'dias',
                    sort: 'asc',
                  },
                ]}
                columnBuffer={8}
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                localeText={{
                  noRowsLabel: 'Nenhum registro encontrado',
                  columnMenuLabel: 'Menu',
                  columnMenuFilter: 'Filtrar',
                  columnMenuHideColumn: 'Esconder',
                  columnMenuUnsort: 'Não ordenar',
                  columnMenuSortAsc: 'Ordernar ASC',
                  columnMenuSortDesc: 'Ordernar DESC',
                  columnMenuShowColumns: 'Mostrar colunas',
                }}
              />
            </div>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};
export default AbaCondicao;
