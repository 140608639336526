import Button from '@atlaskit/button';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AiOutlineGlobal, AiOutlineSearch } from 'react-icons/ai';
import { FaWhatsapp } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {
  InputCidade,
  InputMaskCelular,
  InputMaskCep,
  InputMaskCnpj,
  InputMaskTelefone,
  InputText,
} from '~/components/NovosInputs';
import { ICEP, ICNPJ } from '~/components/Pessoa/types';
import Separator from '~/components/Separator';
import api from '~/services/api';
import { Container } from '../../styles';
import { useTransportadora } from '../../TransportadoraContext';

const MySwal = withReactContent(Swal);

const AbaGeral: React.FC = () => {
  const {
    errors,
    control,
    loadingIcon,

    register,
    inputSite,
    setInputSite,

    setLoadingIcon,
    getValues,
    setValue,
    setCidade,
    cidadeSelected,
    clearCidade,
    cidade,
    setCidadeSelected,
    onRowClick,
    documentoValido,
    resetFormData,
  } = useTransportadora();

  const enterValuesDataPublic = useCallback(
    (cnpjApi: any) => {
      setValue('name', cnpjApi.data.razao_social);
      setValue('apelido', cnpjApi.data.nome_fantasia);
      setValue(
        'telefone',
        cnpjApi.data.ddd_telefone_1 !== ''
          ? cnpjApi.data.ddd_telefone_1.trim()
          : cnpjApi.data.ddd_telefone_1,
      );
      setValue(
        'celular',
        cnpjApi.data.ddd_telefone_1 !== ''
          ? cnpjApi.data.ddd_telefone_1.trim()
          : cnpjApi.data.ddd_telefone_1,
      );
      setValue('cep', cnpjApi.data.cep);
      setValue('logradouro', cnpjApi.data.logradouro);
      setValue('numero', cnpjApi.data.numero);
      setValue('bairro', cnpjApi.data.bairro);
      setValue('complemento', cnpjApi.data.complemento);
      setCidade({
        cidade: cnpjApi.data.municipio,
        uf: cnpjApi.data.uf,
      });
      setValue(
        'nascimentoAbertura',
        cnpjApi.data.data_inicio_atividade
          ? moment(cnpjApi.data.data_inicio_atividade)
              .local()
              .format('YYYY-MM-DD')
          : null,
      );
    },
    [setValue],
  );

  const enterValuesFlg_transportadoraFalse = useCallback(
    (data: any) => {
      setValue('name', data.nome_pessoa);
      setValue('num_ie', data.num_ie);
      setValue('num_cnpj', data.num_cpf_cnpj);
      setValue(
        'telefone',
        data.num_telefone !== '' ? data.num_telefone.trim() : data.num_telefone,
      );
      setValue(
        'celular',
        data.num_celular ? data.num_celular.trim() : data.num_celular,
      );
      setValue('contato', data.des_contato);
      setValue('email', data.email);
      setValue('site', data.site);

      setValue('cep', data.num_cep);
      setValue('logradouro', data.des_logradouro);
      setValue('numero', data.num_endereco);
      setValue('bairro', data.des_bairro);
      setValue('complemento', data.des_complemento);
      setCidade({
        cidade: data.des_cidade,
        uf: data.des_uf,
      });
    },
    [setValue],
  );

  const searchEmpresa = useCallback(async () => {
    setLoadingIcon('buscaPessoa');

    let cnpj = getValues('num_cnpj');
    const isValidCnpj = documentoValido(cnpj);
    if (!isValidCnpj) {
      setLoadingIcon('');
      return;
    }
    cnpj = cnpj.replace(/[^\d]/g, '');

    const { data } = await api.get(`/transportadora/${cnpj}`);

    if (data.success && data.data && data.data.length > 0) {
      await MySwal.fire({
        title: 'Transportador já cadastrada',
        text: `Já existe uma Transportadora cadastrada com documento ${cnpj}. Deseja editar o registro?`,
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result: any) => {
        if (result.isConfirmed) {
          const row: any = { row: data.data[0] };
          onRowClick(row);
          setLoadingIcon('');
        } else {
          setLoadingIcon('');
          return false;
        }
      });
    } else {
      const { data: dataBusca } = await api.get(
        `/pessoas/num_cpf_cnpj/${cnpj}`,
      );
      if (
        dataBusca.success &&
        dataBusca.data &&
        dataBusca.data.length > 0 &&
        !dataBusca.data[0].flg_transportadora
      ) {
        toast.success(`Documento já cadastrado. Dados importados com sucesso.`);
        setLoadingIcon('');
        setValue('cod_pessoa', dataBusca.data[0].cod_pessoa);
        setValue('cod_contato', dataBusca.data[0].cod_contato);
        setValue('cod_endereco', dataBusca.data[0].cod_endereco);
        setValue('num_ie', dataBusca.data[0].num_ie);
        setValue('name', dataBusca.data[0].nome_pessoa);
        setValue('apelido', dataBusca.data[0].apelido_fantasia);
        setValue('telefone', dataBusca.data[0].num_telefone);
        setValue('celular', dataBusca.data[0].num_celular);
        setValue('contato', dataBusca.data[0].des_contato);
        setValue('email', dataBusca.data[0].email);
        setValue('site', dataBusca.data[0].site);
        setValue('cep', dataBusca.data[0].num_cep);
        setValue('logradouro', dataBusca.data[0].des_logradouro);
        setValue('numero', dataBusca.data[0].num_endereco);
        setValue('bairro', dataBusca.data[0].des_bairro);
        setValue('complemento', dataBusca.data[0].des_complemento);
        setCidade({
          cidade: dataBusca.data[0].des_cidade,
          uf: dataBusca.data[0].des_sigla,
        });
        setValue(
          'nascimentoAbertura',
          dataBusca.data[0].dta_nascimento_abertura
            ? moment(dataBusca.data[0].dta_nascimento_abertura)
                .local()
                .format('YYYY-MM-DD')
            : null,
        );
      } else {
        try {
          const cnpjApi: ICNPJ = await axios.get(
            `https://brasilapi.com.br/api/cnpj/v1/${cnpj}`,
          );

          if (cnpjApi.data) {
            enterValuesDataPublic(cnpjApi);
            setLoadingIcon('');
            return;
          }
        } catch {
          toast.warn(
            'Não foram encontrados dados públicos para o documento informado.',
          );
          setLoadingIcon('');
        }
      }
    }
  }, [
    getValues,
    onRowClick,
    resetFormData,
    enterValuesDataPublic,
    enterValuesFlg_transportadoraFalse,
  ]);

  const inputCidadeRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (inputCidadeRef.current) {
      const inputCidade: HTMLInputElement | null =
        inputCidadeRef.current.querySelector('[name="cidades"]');

      if (inputCidade && cidadeSelected) {
        const { label } = cidadeSelected;
        const { value } = inputCidade;

        if (value !== label) inputCidade.value = label;
      }
    }
  }, [cidadeSelected]);

  const openSite = useCallback((site) => {
    if (!site) {
      toast.warning('Informe um site válido');
      return;
    }
    window.open(`https://${site}`);
  }, []);

  const openWhatsApp = useCallback(() => {
    let celular = getValues('celular');
    const contato = getValues('contato');

    const regex = new RegExp(
      /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/g,
    );
    const validacao = !regex.test(celular);

    celular = celular.replace(/[^\d]/g, '');

    if (!celular || validacao) {
      toast.warning('Informe um número de celular válido');
      return;
    }

    celular = celular.replace(/[^\d]/g, '');
    if (!contato) {
      window.open(`https://api.whatsapp.com/send?phone=55${celular}`);
      return;
    }

    window.open(
      `https://api.whatsapp.com/send?phone=55${celular}&text=${encodeURI(
        `Olá, ${contato}`,
      )}`,
    );
  }, [getValues]);

  const searchCep = useCallback(async () => {
    if (loadingIcon !== 'cep') {
      setLoadingIcon('cep');
      let cep = getValues('cep');
      if (!cep) {
        toast.warning('CEP inválido');
        setLoadingIcon('');
        return;
      }
      cep = cep.replace(/[^\d]/g, '');
      if (cep === '') {
        toast.warning('CEP inválido');
        setLoadingIcon('');
        return;
      }
      try {
        const data: ICEP = await axios.get(
          `https://brasilapi.com.br/api/cep/v2/${cep}`,
        );
        if (data.data && !data.message) {
          setValue(
            'logradouro',
            data.data.street && data.data.street.toUpperCase(),
          );
          setValue(
            'bairro',
            data.data.neighborhood && data.data.neighborhood.toUpperCase(),
          );
          setCidade({
            cidade: data.data.city && data.data.city.toUpperCase(),
            uf: data.data.state,
          });
          setValue('latitude', data.data.location.coordinates.latitude);
          setValue('longitude', data.data.location.coordinates.longitude);
          setLoadingIcon('');
          return;
        }
        if (data.message) {
          setLoadingIcon('');
          return;
        }
      } catch (error: any) {
        setLoadingIcon('');
        toast.warn('CEP não encontrado');
      }
      setLoadingIcon('');
    }
  }, [getValues, setValue, loadingIcon]);

  return (
    <Container>
      <Row>
        <Col sm={12} md={3}>
          <div className="input-container">
            <InputMaskCnpj
              label="CNPJ"
              name="num_cnpj"
              isError={!!errors.num_cnpj}
              control={control}
            />
            <Button
              type="button"
              className={
                loadingIcon === 'buscaPessoa'
                  ? 'min-button-loading'
                  : 'min-button'
              }
              onClick={searchEmpresa}
            >
              {loadingIcon === 'buscaPessoa' ? (
                <CircularProgress style={{ color: '#fff' }} size={15} />
              ) : (
                <AiOutlineSearch color="#fff" />
              )}
            </Button>
          </div>
        </Col>
        <Col sm={12} md={3}>
          <InputText
            label="IE"
            maxLength={100}
            name="num_ie"
            register={register}
            placeholder="Inscrição Estadual"
            disabled={false}
            toLowerCase={false}
            isError={!!errors.num_ie}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputText
            label="RNTRC(ANTT)"
            maxLength={10}
            name="rntrc"
            register={register}
            disabled={false}
            isError={!!errors.rntrc}
          />
        </Col>

        <Col sm={12} md={12}>
          <InputText
            label="Nome"
            maxLength={100}
            placeholder="Informe o Nome"
            name="name"
            toLowerCase={false}
            register={register}
            disabled={false}
            isError={!!errors.name}
          />
        </Col>
        <Col sm={12}>
          <Separator labelText="Contato Principal" />
        </Col>
        <Col sm={12} md={2}>
          <InputMaskTelefone
            label="Telefone"
            name="telefone"
            isError={!!errors.telefone}
            control={control}
          />
        </Col>
        <Col sm={12} md={2}>
          <div className="input-container">
            <InputMaskCelular
              label="Celular"
              name="celular"
              isError={!!errors.celular}
              control={control}
            />
            <Button onClick={openWhatsApp} type="button" className="min-button">
              <FaWhatsapp color="#fff" style={{ marginTop: '-2px' }} />
            </Button>
          </div>
        </Col>
        <Col sm={12} md={8}>
          <InputText
            label="Contato"
            maxLength={50}
            placeholder="Informe o Contato"
            name="contato"
            toLowerCase={false}
            register={register}
            disabled={false}
            isError={!!errors.contato}
          />
        </Col>
        <Col sm={12} md={6}>
          <InputText
            label="E-mail"
            maxLength={100}
            placeholder="Informe o E-mail"
            name="email"
            toLowerCase
            register={register}
            disabled={false}
            isError={!!errors.email}
          />
        </Col>
        <Col sm={12} md={6}>
          <div className="input-container">
            <InputText
              label="Site"
              maxLength={150}
              placeholder="Informe a página WEB"
              name="site"
              toLowerCase
              register={register}
              disabled={false}
              isError={!!errors.site}
              onChange={(e: any) => {
                setInputSite(e.target.value);
              }}
            />
            <Button
              onClick={() => openSite(inputSite)}
              type="button"
              className="min-button"
            >
              <AiOutlineGlobal color="#fff" style={{ marginTop: '-2px' }} />
            </Button>
          </div>
        </Col>
        <Col sm={12}>
          <Separator labelText="Endereço" />
        </Col>
        <Col sm={12} md={2}>
          <div className="input-container">
            <InputMaskCep
              label="CEP"
              name="cep"
              isError={!!errors.cep}
              control={control}
            />
            <Button type="button" className="min-button" onClick={searchCep}>
              {loadingIcon === 'cep' ? (
                <CircularProgress style={{ color: '#fff' }} size={15} />
              ) : (
                <AiOutlineSearch color="#fff" />
              )}
            </Button>
          </div>
        </Col>
        <Col sm={12} md={5}>
          <InputText
            label="Logradouro"
            maxLength={100}
            placeholder="Informe o Logradouro"
            name="logradouro"
            toLowerCase={false}
            register={register}
            disabled={false}
            isError={!!errors.logradouro}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputText
            label="Número"
            maxLength={15}
            placeholder="Informe o Número"
            name="numero"
            register={register}
            disabled={false}
            isError={!!errors.numero}
          />
        </Col>
        <Col sm={12} md={3}>
          <InputText
            label="Bairro"
            maxLength={100}
            placeholder="Informe o Bairro"
            name="bairro"
            toLowerCase={false}
            register={register}
            disabled={false}
            isError={!!errors.bairro}
          />
        </Col>
        <Col sm={12} md={8}>
          <InputText
            label="Complemento"
            maxLength={50}
            placeholder="Informe o Complemento"
            name="complemento"
            toLowerCase={false}
            register={register}
            disabled={false}
            isError={!!errors.complemento}
          />
        </Col>
        <Col sm={12} md={4} ref={inputCidadeRef}>
          <InputCidade
            label="Cidade"
            maxLength={29}
            placeholder="Selecione a Cidade"
            name="cidades"
            register={register}
            isError={!!errors.cidades}
            control={control}
            cidadeABuscar={cidade}
            clearCampo={clearCidade}
            changeSelected={(selected) => {
              setValue('cidades', selected);
              setCidadeSelected(selected);
            }}
            setValue={setValue}
          />
        </Col>
      </Row>
    </Container>
  );
};
export default AbaGeral;
