import React, { useCallback, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';

import Search from '~/components/Search';
import api from '~/services/api';
import { validar } from '~/utils/ie';
import { Container } from './styles';
import { CondicaoData, SelectEntidades } from './types';

import { useTransportadora } from './TransportadoraContext';
import AbaGeral from './Abas/AbaGeral';
import AbaCondicao from './Abas/AbaCondicao';

const MySwal = withReactContent(Swal);

const CadastroDeTransportadora: React.FC = () => {
  const {
    setFinalizadoras,
    setValueCondicao,
    setCondicoesOptions,
    finalizadoras,
    condicoesOptions,
    setUpdate,
    setShowSearch,
    setTab,
    getValues,
    condicoes,

    setLoadingIcon,
    setLoading,
    setError,
    isUpdate,
    setLoader,

    loader,
    showSearch,
    setClickSubmit,
    tab,
    handleSubmit,

    documentoValido,
    resetFormData,
    onRowClick,
    condicaoInsert,
    condicaoUpdate,
    condicaoDelete,
  } = useTransportadora();

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/finalizadora');
      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data.map((ent: SelectEntidades) => ({
            label: `${ent.des_finalizadora}`,
            value: ent.cod_finalizadora,
          }));

          setFinalizadoras(options);
        }
      }

      setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
      setValueCondicao('numCondicao', 30);
    })();
  }, [setValueCondicao]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/condicao');
      if (data.success && data.message !== 'Nenhum registro encontrado.') {
        const formatedOptions = data.data.map((cond: CondicaoData) => ({
          label: `${cond.des_condicao} - ${cond.des_definicao}`,
          value: cond.cod_condicao,
        }));
        setCondicoesOptions(formatedOptions);
      }
    })();
  }, []);

  function getArrayIndexed(arr: any, fieldNameKey: any, fieldNameValue: any) {
    const r: any = {};
    for (let i = 0; i < arr.length; i++)
      r[`${arr[i][fieldNameKey]}`] = arr[i][fieldNameValue];
    return r;
  }
  const indiceValorFinalizadora = getArrayIndexed(
    finalizadoras,
    'value',
    'label',
  );
  const indiceValorCondicao = getArrayIndexed(
    condicoesOptions,
    'value',
    'label',
  );

  /**
   * Métodos de submit dos dados da tela para api
   */
  const prepareData = useCallback(
    (data: any) => {
      const numCnpj = data.num_cnpj;

      const pessoa = {
        cod_pessoa: data.cod_pessoa,
        cod_transportadora: data.cod_transportadora,
        cod_contato: data.cod_contato,
        cod_endereco: data.cod_endereco,
        num_ie: data.num_ie.includes('ISENTO')
          ? data.num_ie
          : data.num_ie.replace(/[^\d]/g, ''),
        rntrc: data.rntrc,
        flg_transportadora: true,
        nome_pessoa: data.name,
        tipoPessoa: 1,
        num_cpf_cnpj: numCnpj && numCnpj.replace(/[^\d]/g, ''),
        contato: {
          num_telefone: data.telefone,
          num_celular: data.celular,
          des_contato: data.contato,
          site: data.site,
          email: data.email,
        },
        endereco: {
          num_cep: data.cep,
          des_logradouro: data.logradouro,
          num_endereco: data.numero,
          des_complemento: data.complemento,
          des_bairro: data.bairro,
          des_cidade: data.cidades.label.split(' - ')[0],
          cod_cidade: data.cidades.value,
          des_uf: data.cidades.label.split(' - ')[1],
          des_referencia: data.referencia,
          latitude: data.latitude,
          longitude: data.longitude,
        },
      };

      const masterDetailPrepare = [
        {
          obj_name: 'condicao',
          pk_fields: ['cod_seq_pessoa_condicao'],
          itens: {
            insert: condicaoInsert,
            update: condicaoUpdate,
            delete: condicaoDelete,
          },
        },
      ];

      return {
        pessoa,
        masterDetail: masterDetailPrepare,
      };
    },
    [condicoes],
  );

  const onSubmit = handleSubmit(async (data: any) => {
    setTab('geral');

    data.num_ie = data.num_ie.trim();
    if (data.num_ie === '' || !data.num_ie) {
      setLoading(false);
      const result = await MySwal.fire({
        title: 'Confirmação',
        text: 'Inscrição Estadual não foi informada. Deseja cadastrá-lo como "ISENTO"',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      });

      if (!result.isConfirmed) {
        setError('num_ie', {
          type: 'error',
        });
        return;
      }
      data.num_ie = 'ISENTO';
    } else {
      const ie = getValues('num_ie');
      const cidades = getValues('cidades');

      if (!cidades || !cidades.ufValue) {
        return true; // No validation if no state is selected
      }

      if (!validar(ie, cidades.ufValue)) {
        setError('num_ie', {
          type: 'error',
        });
        return;
      }
    }

    try {
      setLoading(true);
      const isValidCnpj = documentoValido(data.num_cnpj);
      if (!isValidCnpj) {
        setLoadingIcon('');
        return;
      }
      const formatedData = prepareData(data);

      setLoader(true);
      if (isUpdate) {
        const { data: dataReturn }: any = await api.put(
          `/transportadora`,
          formatedData,
        );
        if (dataReturn.success) {
          toast.success(dataReturn.message);
          setShowSearch(true);
          setUpdate(false);
          resetFormData();
          setLoader(false);
          return;
        }
        setLoader(false);
        return;
      }
      const { data: dataReturn }: any = await api.post(
        '/transportadora',
        formatedData,
      );
      if (dataReturn.success) {
        toast.success(dataReturn.message);
        resetFormData();
        setLoader(false);
        return;
      }
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
    }
  });

  const onDelete = useCallback(async () => {
    const codTransportadora = getValues('cod_transportadora');
    if (!codTransportadora) {
      toast.warning('Transportadora não encontrado');
      return;
    }
    const { data } = await api.delete(`/transportadora/${codTransportadora}`);

    if (data.success) {
      toast.success('Transportadora inativada com sucesso');
      resetFormData();
      setUpdate(false);
      setShowSearch(true);
    }
  }, [getValues, resetFormData]);

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={208}
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
        />
      )}
      {!showSearch && (
        <FormDefault
          title="Cadastro de Transportadora"
          codTela={208}
          codigoRegistro={[
            {
              value: getValues('cod_transportadora'),
              des_campo: 'Código',
            },
          ]}
          onSave={async () => {
            await onSubmit();
            setTab('geral');
            setClickSubmit(true);
          }}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => {
            resetFormData();
            setUpdate(false);
          }}
          onDelete={() => onDelete()}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            resetFormData();
            setUpdate(false);
            setShowSearch(true);
          }}
          showSwitch={false}
          isDelete={false}
          labelButtonTrash="Inativar"
          deleteMessage="Deseja realmente inativar o registro?"
        >
          <Tabs
            id="controlled-tab-cliente"
            activeKey={tab}
            onSelect={(k) => setTab(k)}
            style={{ marginTop: '20px' }}
          >
            <Tab eventKey="geral" title="Geral">
              <AbaGeral />
            </Tab>
            <Tab eventKey="condicao" title="Condição">
              <AbaCondicao />
            </Tab>
          </Tabs>
        </FormDefault>
      )}
    </Container>
  );
};
export default CadastroDeTransportadora;
