import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .btnLimiteCreditoTransportadora {
    float: right;
    padding: 8px 8px 8px 8px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    /* margin-bottom: 5px; */
    transition: 0.4s;
    color: #424242;
  }
  .btnLimiteCreditoTransportadora:hover {
    background-color: #f4f5f7;
    color: #000;
    transition: 0.4s;
  }
  .accordion-collapse {
    border-color: transparent;
    border: none !important;
  }
  .actButtonsCredito {
    margin-top: 28px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;

    @media screen and (max-width: 1199px){
      .confirm {
          min-width:130px !important;
        }
        .cancel{
          min-width:130px !important;
        }
    }

    @media screen and (max-width: 991px){
      .confirm {
          min-width:100px !important;
        }
        .cancel{
          min-width:100px !important;
        }
    }
  }
  .tipoCondicao {
    max-width: 300px;
  }

  @media screen and (max-width: 1199px) {
    .tipoCondicao {
      max-width: 230px;
    }

    .buttonContainer {
      .confirm {
        min-width: 130px;
      }
      .cancel {
        min-width: 130px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .buttonContainer {
      min-width: 100%;
    }
    .tipoCondicao {
      width: 40%;
    }
    .finalizadoraContainer {
      width: 41%;
    }
  }

  @media screen and (max-width: 767px) {
    .buttonContainer {
      width: 100%;
    }
    .tipoCondicao {
      max-width: 100%;
      width: 100%;
    }
    .finalizadoraContainer {
      width: 100%;
    }
  }
  .inputDiasContainer {
    input {
      margin-top: 2px;
      height: '40px';
    }
  }

  .containerFinalizadora {
    @media screen and (max-width: 991px) {
      width: 43%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .numberContainer {
    max-width: 100px;
    padding-left: 0px;
    padding-right: 0px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      padding-right: calc(var(--bs-gutter-x) / 2);
      padding-left: calc(var(--bs-gutter-x) / 2);
    }
  }

  .condicaoButton {
    display: flex;
    button {
      width: 100%;
    }
  }
  .actButtonsCredito {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    @media screen and (min-width: 1000px) {
      button {
        width: 100%;
      }
    }

    @media screen and (max-width: 1000px) {
      .confirm {
        min-width: 130px !important;
        height: 42px;
      }
      .cancel {
        min-width: 130px !important;
        height: 42px;
      }
    }

    @media screen and (max-width: 991px) {
      margin-right: 0px !important .confirm {
        min-width: 100px !important;
      }
      .cancel {
        min-width: 150px !important;
      }
    }
  }
  .inputDiasContainer {
    input {
      margin-top: 2px;
      height: '40px';
    }
  }


  .tipoCondicao {
    max-width: 300px;
  }

  @media screen and (max-width: 1199px){
    .tipoCondicao {
        max-width: 230px;
    }
  }

  @media screen and (max-width: 991px){
    .buttonContainer{
      min-width: 100%;
    }
    .tipoCondicao {
      width: 40%;
    }
    .finalizadoraContainer{
      width: 41%;
    }
  }

  @media screen and (max-width: 767px) {
    .buttonContainer{
      width: 100%;
    }
    .tipoCondicao {
      max-width: 100%;
      width: 100%;
    }
    .finalizadoraContainer{
      width: 100%;
    }
  }

  button:focus {
    outline: 0;
  }
  .btn-outline-danger:hover {
    background-color: #d94848;
    color: #fff;
  }
  .inputButton {
    float: right;
    height: 40px;
    line-height: 40px;
    margin-top: -40px;
    align-items: center;
    justify-content: center;
  }
  .input-container {
    position: relative;
    align-items: center;
    .min-button {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 0;
      margin-bottom: 0.0938rem;
      height: 2.425rem;
      width: 40px;
      background: #8850bf;
      border-radius: 0px 4px 4px 0px;
      &:hover {
        background: #57069e;
      }
      &:focus {
        background: #57069e;
      }
    }
    .min-button-loading {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 0;
      height: 37px;
      width: 40px;
      background: #8850bf;
      border-radius: 0px 4px 4px 0px;
      cursor: not-allowed
      &:hover {
        background: #57069e;
      }
      &:focus {
        background: #57069e;
      }
    }

    .form-control {
      padding: 0.375rem 0.75rem;
    }

    .form-control.is-invalid {
      background-image: none;
      border: 2px solid var(--ds-border-neutral, #dfe1e6);
      border-bottom: 2px solid #cf1919 !important ;
      &:focus {
        box-shadow: none;
      }
      padding: -0.375rem -0.75rem !important;
    }
  }

  .selectContainer .form-group {
    margin-left: -8px;
  }

  .MuiDataGrid-columnHeader--moving {
    background-color: #8850bf !important;

    .MuiDataGrid-columnSeparator{
      display:none;
    }
  }
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
  }
`;
