import * as yup from 'yup';

const SITE_NO_REGEX =
  /((http|https):\/\/)?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/;
export const schema = yup
  .object({
    num_cnpj: yup
      .string()
      .required()
      .test((value) => {
        // Remove os caracteres especiais e verifica possui 11 ou 14 digitos
        value = value?.replace(/\D/g, '');
        return value?.length === 14;
      }),
    name: yup.string().required(),
    email: yup.string().email().notRequired().nullable(true),
    site: yup
      .string()
      .matches(SITE_NO_REGEX, {
        message: 'not valid site no',
        excludeEmptyString: true,
      })
      .notRequired()
      .nullable(true),
    cep: yup.string().required(),
    logradouro: yup.string().required(),
    numero: yup.string().required(),
    bairro: yup.string().required(),
    cidades: yup
      .object()
      .shape({
        value: yup.number().required(),
        ufValue: yup.string().notRequired(),
        label: yup.string().required(),
      })
      .required(),
    num_ie: yup.string().notRequired(),
    rntrc: yup.string(),
  })
  .required();

export const condicao = yup
  .object({
    condicaoFinalizadora: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    condicao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    numCondicao: yup.number().required(),
  })
  .required();
